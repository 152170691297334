// Generated by Framer (3fa6aa4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Image, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/qcg0OEbTe";

const cycleOrder = ["ChSQ8y3zV", "LH9N50qnr"];

const serializationHash = "framer-Tqlx3"

const variantClassNames = {ChSQ8y3zV: "framer-v-wvbizn", LH9N50qnr: "framer-v-1ppg02f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Card With Icon sm Mobile": "LH9N50qnr", "Card With Icon sm": "ChSQ8y3zV"}

const getProps = ({height, icon, id, title, width, ...props}) => { return {...props, jkrzsTiJ1: icon ?? props.jkrzsTiJ1 ?? {src: "https://framerusercontent.com/images/3LZcsrJbmHpoADXPZfKJ5FTv5Y.svg"}, UB7zfePa8: title ?? props.UB7zfePa8 ?? "Secure", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ChSQ8y3zV"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;icon?: {src: string; srcSet?: string};title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, jkrzsTiJ1, UB7zfePa8, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ChSQ8y3zV", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-wvbizn", className, classNames)} data-framer-name={"Card With Icon sm"} layoutDependency={layoutDependency} layoutId={"ChSQ8y3zV"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-47ef7e86-9ea6-47bf-9a30-889f143a325f, rgb(255, 255, 255))", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}} variants={{LH9N50qnr: {borderBottomLeftRadius: 15, borderBottomRightRadius: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15}}} {...addPropertyOverrides({LH9N50qnr: {"data-framer-name": "Card With Icon sm Mobile"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: "24px", ...toResponsiveImage(jkrzsTiJ1)}} className={"framer-1nlbz9w"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"aawbVkSFT"} {...addPropertyOverrides({LH9N50qnr: {background: {alt: "", fit: "fill", sizes: "20px", ...toResponsiveImage(jkrzsTiJ1)}}}, baseVariant, gestureVariant)}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1p685yq"} data-styles-preset={"qcg0OEbTe"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-b32213de-a677-4f6d-93ea-f0b7678127df, rgb(72, 94, 121)))"}}>Secure</motion.p></React.Fragment>} className={"framer-pthzgw"} data-framer-name={"TItle"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"b6hLKyEDN"} style={{"--extracted-r6o4lv": "var(--token-b32213de-a677-4f6d-93ea-f0b7678127df, rgb(72, 94, 121))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={UB7zfePa8} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Tqlx3.framer-17hs17n, .framer-Tqlx3 .framer-17hs17n { display: block; }", ".framer-Tqlx3.framer-wvbizn { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 16px; height: min-content; justify-content: center; overflow: hidden; padding: 14px 26px 14px 26px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-Tqlx3 .framer-1nlbz9w { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); overflow: hidden; position: relative; width: 24px; }", ".framer-Tqlx3 .framer-pthzgw { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Tqlx3.framer-wvbizn { gap: 0px; } .framer-Tqlx3.framer-wvbizn > * { margin: 0px; margin-left: calc(16px / 2); margin-right: calc(16px / 2); } .framer-Tqlx3.framer-wvbizn > :first-child { margin-left: 0px; } .framer-Tqlx3.framer-wvbizn > :last-child { margin-right: 0px; } }", ".framer-Tqlx3.framer-v-1ppg02f.framer-wvbizn { gap: 5px; padding: 6px 14px 6px 14px; }", ".framer-Tqlx3.framer-v-1ppg02f .framer-1nlbz9w { height: var(--framer-aspect-ratio-supported, 20px); width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Tqlx3.framer-v-1ppg02f.framer-wvbizn { gap: 0px; } .framer-Tqlx3.framer-v-1ppg02f.framer-wvbizn > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-Tqlx3.framer-v-1ppg02f.framer-wvbizn > :first-child { margin-left: 0px; } .framer-Tqlx3.framer-v-1ppg02f.framer-wvbizn > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 57.5
 * @framerIntrinsicWidth 151
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"LH9N50qnr":{"layout":["auto","auto"]}}}
 * @framerVariables {"jkrzsTiJ1":"icon","UB7zfePa8":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerlhx5CzCMO: React.ComponentType<Props> = withCSS(Component, css, "framer-Tqlx3") as typeof Component;
export default Framerlhx5CzCMO;

Framerlhx5CzCMO.displayName = "Card With Icon sm";

Framerlhx5CzCMO.defaultProps = {height: 57.5, width: 151};

addPropertyControls(Framerlhx5CzCMO, {variant: {options: ["ChSQ8y3zV", "LH9N50qnr"], optionTitles: ["Card With Icon sm", "Card With Icon sm Mobile"], title: "Variant", type: ControlType.Enum}, jkrzsTiJ1: {__defaultAssetReference: "data:framer/asset-reference,3LZcsrJbmHpoADXPZfKJ5FTv5Y.svg?originalFilename=default-icon.svg&preferredSize=auto", title: "Icon", type: ControlType.ResponsiveImage}, UB7zfePa8: {defaultValue: "Secure", displayTextArea: false, placeholder: "Title", title: "Title", type: ControlType.String}} as any)

addFonts(Framerlhx5CzCMO, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})